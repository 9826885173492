import { FontAwesomeIcon } from "helpers/helpers";
import React from "react";

interface AnimatedIconButtonProps {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  ariaLabel: string;
  icon: any;
  color?: string;
  disabled?: boolean;
}

export default function AnimatedIconButton({
  onClick,
  ariaLabel,
  icon,
  color,
  disabled,
}: AnimatedIconButtonProps) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      aria-label={ariaLabel}
      className={`relative border inline-flex items-center justify-center p-3 w-10 h-10 overflow-hidden rounded-full group bg-transparent hover:bg-purple-100 focus:outline-none focus:ring-2 focus:ring-pink-100 focus:ring-offset-2 transition-all duration-300 ease-in-out`}
      style={{ color: disabled ? "#ccc" : color ? color : "#4e19b0" }}
    >
      {!disabled && (
        <span
          style={{ backgroundColor: color ? color : "#4e19b0" }}
          className={`absolute w-0 h-0 transition-all duration-300 ease-out rounded-full group-hover:w-full group-hover:h-full `}
        ></span>
      )}
      <span className="relative transition-colors duration-300 ease-in-out group-hover:text-white">
        {icon}
      </span>
    </button>
  );
}
